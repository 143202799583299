import React from "react"
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row } from "react-bootstrap"
import * as garmentStyles from "../css/garment.module.css"
import * as homeStyles from "../css/index.module.css"
import { Icon } from '@iconify/react';
import chevronDoubleRight from '@iconify/icons-mdi-light/chevron-double-right';
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Bgother from "../components/bgother"
import Seo from "../components/seo"

const SeoTitle = "สินค้าอุปกรณ์อื่นๆ"
const SeoDecription = "สินค้าอุปกรณ์อื่นๆ มากมายที่รอให้ผู้ซื้อได้สัมผัส เราผู้ผลิตนำเข้าและจำหน่ายอุปกรณ์ตัดเย็บ เส้นด้ายอุตสาหกรรม ทุกชนิด"
const url = "/other-products/"

export default function otherproduct({data}) {
    return (
    <Containers>
      <Seo 
        title={SeoTitle}
        description={SeoDecription}
        pathname={url}
       />
      <Headertop />
      <Header />
      <Bgother />
      <div className={garmentStyles.herob}>
      <Container fluid="xl">
              <Row>
                  <Col><p><Link to="/">Home</Link>  <Icon icon={chevronDoubleRight} />  Product</p></Col>
              </Row>
      </Container>
      </div>
      <div className={garmentStyles.herosection}>
          <Container fluid="xl">
              <Row>
                <Col md={3}>
                <div className={garmentStyles.categories}>
                  <h3>หมวดหมู่สินค้า</h3>
                  <Link to="/categories/"><p className={garmentStyles.pmenu}>สินค้าทั้งหมด</p></Link>
                  <Link to="/garment/"><p className={garmentStyles.pmenu}>งานการ์เม้นท์</p></Link>
                  <Link to="/sew-a-sack/"><p className={garmentStyles.pmenu}>ด้ายเย็บกระสอบ</p></Link>
                  <Link to="/industrial-thread/"><p className={garmentStyles.pmenu}>ด้ายอุตสหกรรม</p></Link>
                  <Link to="/product-import/"><p className={garmentStyles.pmenu}>สินค้านำเข้า</p></Link>
                  <Link to="/other-products/"><p className={garmentStyles.pmenuactive}>สินค้าอื่นๆ</p></Link>
                </div>
                </Col>
                <Col md={9}>
                  <Containers>
                    <Row>
                      {data.allMarkdownRemark.edges.map(({ node }) => (
                        <Col xs={6} md={4} key={node.id} className={homeStyles.colproduct}>
                          <Link to={node.fields.slug}>
                            <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} className={homeStyles.imgproduct} />
                            <h3>{node.frontmatter.title}{" "}</h3>
                          </Link>
                        </Col>))}
                    </Row>
                  </Containers>
                </Col>
              </Row>
          </Container>
      </div>
        <Container className="pb-5">
          <Row>
            <Col md={12}>
              <p>สินค้าอื่นๆ ในอุตสาหกรรมตัดเย็บ มีหลายชนิดและครบวงจรเพื่อรองรับความต้องการของการผลิตเสื้อผ้าและผลิตภัณฑ์ที่เกี่ยวข้อง</p>
              <h2>สินค้าอื่นๆ ในอุตสาหกรรมตัดเย็บ ที่เกี่ยวข้องได้แก่</h2>
                <ul>
                  <li>เครื่องตัด Cutting Machines: เครื่องตัดมีหลายประเภทเช่น เครื่องตัดไหม, เครื่องตัดผ้า, และเครื่องตัดวัสดุทั่วไป นอกจากนี้ยังมีเครื่องตัดด้ายและเครื่องตัดสายไฟ</li>
                  <li>จักรเย็บ Sewing Machines: มีหลายประเภทของจักรเย็บ, เช่น จักรเย็บอุตสาหกรรมที่ใช้ในการผลิตเสื้อผ้า, จักรเย็บลำโพงที่ใช้ในการทำถุง, และจักรเย็บหนัง</li>
                  <li>กรรไกรตัด Scissors: กรรไกรตัดเย็บมีหลายประเภทเช่น กรรไกรตัดผ้า, กรรไกรตัดด้าย, และกรรไกรตัดสายไฟ</li>
                  <li>สายใย Thread: สายใยเย็บมีหลายประเภท เช่น สายใยโพลีเอสเตอร์, สายใยไหม, และสายใยนำไฟ</li>
                  <li>กรรไกรตัดระบบไฟฟ้า Electric Cutting Tools: เครื่องมือที่ใช้ในกระบวนการตัดและปรับแต่งวัสดุต่าง ๆ โดยใช้ระบบไฟฟ้า</li>
                  <li>สินค้าเสริมสำหรับเครื่องเย็บ Sewing Accessories: เช่น เข็ม, ปีน, กลมี, ปีก, และอุปกรณ์เสริมที่ใช้เพื่อความสะดวกในการเย็บ</li>
                  <li>อุปกรณ์และเครื่องมือปรับแต่ง Finishing Tools and Equipment: เช่น ไอรอน, เครื่องตีรอบ, และเครื่องทำลายผ้า</li>
                  <li>ระบบจัดเก็บสินค้า Storage Systems: ระบบที่ใช้ในการจัดเก็บสินค้าที่ใช้ในกระบวนการตัดเย็บเพื่อความเรียบร้อยและความสะดวกในการใช้งาน</li>
                  <li>ซอฟท์แวร์ที่ใช้ในอุตสาหกรรมเสื้อผ้า Software for Apparel Industry: ซอฟท์แวร์ที่ช่วยในการออกแบบ, ตัด, และจัดการกระบวนการผลิต</li>
                  <li>เครื่องมือและอุปกรณ์ทดสอบคุณภาพ Quality Testing Tools and Equipment: เครื่องมือที่ใช้ในการทดสอบคุณภาพของผ้าหรือผลิตภัณฑ์ที่ผลิตขึ้น</li>
                </ul>
              <p>สินค้าเหล่านี้มีบทบาทสำคัญในกระบวนการผลิตเสื้อผ้าและผลิตภัณฑ์ที่เกี่ยวข้อง, ซึ่งต้องการความพิเศษและความแม่นยำในกระบวนการตัดและเย็บ</p>
            </Col>
          </Row>
        </Container>
      <Formfooter />
      <Footer />
    </Containers>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: "other-products" }}}
      sort: { fields: [frontmatter___date], order: DESC }
      ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`